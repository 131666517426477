/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

[dir="ltr"] .ProductCard {
    .AvailabilityStatus {
        &-Qty {
            color: $font-color-2;
            font-weight: 600;

            &_isUnavailable {
                color: $status-red-dark;
                font-weight: 400;
            }
        }
    }

    &-AnnouncementStatus {
        align-items: center;
        background: #faf6eb;
        border-radius: 24px;
        display: flex;
        font-size: 16px;
        font-weight: 600;
        min-height: 25px;
        width: fit-content;
        padding: 0 12px 0 24px;
        margin-right: 24px;
        margin-bottom: 5px;
        margin-top: 6px;
        color: #626262;

        @include mobile {
            font-size: 16px;
            margin-bottom: 5px;
        }

        &::before {
            background: $status-orange-dark;
            border-radius: 10px;
            content: '';
            height: 10px;
            left: 8px;
            position: absolute;
            width: 10px;
            margin-top: 3px;
        }
    }

    .ProductPrice {
        min-height: fit-content;
        display: block;

        &-HighPrice {
            color: $font-color-2;
            font-size: 14px;
            line-height: 18px;
            opacity: 1;
            display: none;
        }

        &-PriceValue {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $font-color-1;
        }

        &_hasDiscount {

            .ProductPrice {
                &-PriceValue {
                    color: $primary-color-1;
                }
            }
        }
    }

    &-Content {
        padding-top: 0;
        padding-left: 8px;
        padding-right: 8px;
    }

    &-Discount {
        span {
            &:nth-of-type(1) {
                color: $font-color-2;
            }
        }
    }

    &-Figure {
        @include desktop {
            border-radius: 2px 2px 0 0;
            padding-top: 10px;
            overflow: hidden;
        }
    }

    &-FigureReview {
        @include desktop {
            border-radius: 2px 2px 0 0;
            padding: 0;
        }
    }

    &-LinkInnerWrapper {
        width: 100%;
        height: 100%;

        border-radius: 2px;

        &:hover {
            @include desktop {
                box-shadow: 2px 2px 18px rgba(0, 0, 0, 0.15);
                position: absolute;
                height: fit-content;
            }
        }

        &_loaded:hover {
            .ProductCard {
                &-Name {
                    @include desktop {
                        min-height: 42px;
                        display: block;
                        height: auto;
                    }
                }

                &-VisibleOnHover {
                    @include desktop {
                        border-radius: 0 0 2px 2px;
                        box-shadow: 2px 2px 18px rgba(0, 0, 0, 0.15);
                    }
                }
            }
        }
    }

    &-PricesWrapper {
        .ProductCard {
            &-PriceLabel {
                color: $font-color-2;
                font-size: 14px;
                font-weight: 400;
                margin-right: 4px;

                @include mobile {
                    display: block;
                    font-size: 12px;
                }
            }

            &-PriceBrutto {
                font-size: 14px;
            }

            &-PriceNetto {
                font-size: 18px;
                font-weight: 700;

                @include mobile {
                    font-size: 15px;
                }
            }
        }

        &_isList {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .ProductCard {
                &-PriceNetto {
                    font-size: 20px;
                    font-weight: 700;
                }
            }
        }

        &_isSignedIn {
            margin-bottom: 8px;

            .ProductCard {
                &-Discount {
                    font-weight: 600;

                    &_isSignedIn {
                        font-size: 16px;
                        min-height: 22px;

                        @include mobile {
                            font-size: 12px;
                        }
                    }
                }

                &-PriceNetto {
                    color: $secondary-color-3;
                }
            }

        }
    }

    &-AddToCartWrapper {
        width: 100%;
    }

    &-VisibleOnHover {
        @include mobile {
            padding: 0;
            position: static;
        }

        .ProductCard-Footer {
            @include mobile {
                width: 100%;
            }

            >div {
                input {
                    border-radius: 2px 0 0 2px;

                }

                +.AddToCart {
                    border-radius: 0 2px 2px 0;

                    @include mobile {
                        padding: 7px;
                        margin-left: -1px;

                        @media (max-width: 406px) {
                            font-size: 12px;
                        }

                        @media (max-width: 382px) {
                            font-size: 11px;
                        }

                        @media (max-width: 368px) {
                            font-size: 10px;
                        }
                    }
                }
            }

            .AddToCart-LoginBlock p {
                @include mobile {
                    text-align: left;
                }
            }
        }

        .Button {
            font-size: 14px;
            height: 36px;

            &.AddToCart {
                background-color: $primary-color-1;
                padding: 0 7px;

                @media (max-width: 356px) {
                    font-size: 12px;
                }
            }

        }

        @include mobile {
            align-items: center;
            display: flex;
            justify-content: center;
            opacity: 1;
            width: inherit;

            .Button {
                height: 36px;
                line-height: 18px;
                min-width: unset;
                padding: 9px 20px;
            }
        }

        .ProductActions {
            &-AttributesWrapper {
                display: none;
            }
        }
    }

    &-Name {
        display: -webkit-box;
        font-size: 14px;
        height: 42px;
        min-height: 40px;
        overflow: hidden;
        white-space: pre-wrap;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-weight: 600;
        line-height: 20px;
        color: $font-color-1;
        text-align: left;
    }

    &-Picture {
        padding-bottom: 100%;
    }

    &-Price {
        align-items: flex-end;
        display: flex;
        flex-direction: row-reverse;
        gap: 6px;
        justify-content: center;
    }

    &-Status {
        color: $font-color-2;
        font-size: 12px;
        margin-top: 8px;

        &Dot {
            display: inline-block;
            background: $status-green-light;
            border-radius: 77px;
            width: 30px;
            height: 8px;
            margin-right: 6px;

            +span {
                color: $font-color-1;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
            }

            &::before {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 0;
                height: 100%;
                border-radius: inherit;
            }

            &_status {
                position: relative;

                &_0 {
                    background: $status-red-light;

                    +span {
                        color: $status-red-dark;
                    }
                }

                &_1 {
                    background: $status-red-light;

                    &::before {
                        width: 8px;
                        background: $status-red-dark;
                    }
                }

                &_2 {
                    background: $status-orange-light ;

                    &::before {
                        width: 15px;
                        background: $status-orange-dark;
                    }
                }

                &_3,
                &_4,
                &_5 {
                    background: $status-green-light;

                    &::before {
                        width: 20px;
                        background: $primary-color-1;
                    }
                }

                &_4 {
                    &::before {
                        width: 24px;
                    }
                }

                &_5 {
                    &::before {
                        width: 26px;
                    }
                }

                &_6 {
                    background: $primary-color-1;

                    &::before {
                        width: 30px;
                    }
                }
            }
        }
    }

    .ProductCard-OutOfStockBtn {
        margin-right: 0;
        max-width: 288px;

        &,
        .Button {
            width: 100%;
        }

        .Button {
            padding-left: 7px;
            padding-right: 7px;
        }
    }

    &-Footer {
        >div {
            margin-right: 0 !important;
        }

        >.AddToCart-LoginBlock {
            width: 100%;
            margin-right: 0;

        }

        .Button {
            height: 36px;
            min-height: 36px;
            margin-right: 0;
        }


        button {
            @include mobile {
                background-color: $white;
            }
        }

    }

    &-LoginButton {
        margin-bottom: 2px;
        width: 100%;

        &Wrapper {
            font-size: 12px;
            text-align: center;
        }
    }

    .ProductCard-Qty {
        margin-top: 0;
        margin-right: 5px;

        @include mobile {
            margin-right: 0;
        }

        input {
            width: 70px;
            height: 36px;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            color: $font-color-1;
            border: 2px solid $disabled-color;
            border-radius: 2px;
            padding: 8px 16px;

            @include mobile {
                width: 54px;
                min-width: unset;
            }
        }
    }

    &_layout {
        &_list {
            width: 100%;
            border-top: 1px solid #D3D8E6;
            margin: 0;
            padding: 40px 20px 40px 10px;

            .ProductCard-OutOfStockBtn {
                grid-column: 1 / 3;
            }

            .ProductCard-ProductActions {
                margin-left: 0;
            }

            .ProductCard-Figure {
                padding-top: 0;
            }

            .ProductCard-Content_layout_list {
                padding: 0;
                max-width: 100%;
                width: 100%;
            }

            .ProductCard-Author {
                display: none;
            }

            .ProductCard-Link {
                width: 100%;

                .Image {
                    width: 159px;
                }
            }

            .Button {
                height: 42px;
                min-height: 42px;
            }

            .ProductCard-MainInfo,
            .ProductCard-MiddleContent {
                display: flex;
                justify-content: space-between;
            }

            .ProductCard-PriceWrapper {
                margin-bottom: 0;
                text-align: right;
            }

            .ProductCard-Name {
                -webkit-line-clamp: 1;
                font-weight: 600;
                font-size: 20px;
                line-height: 26px;
                width: 100%;
                max-width: 579px;
                height: 26px;
                min-height: 26px;
                margin-bottom: 2px;

                a {
                    -webkit-line-clamp: 1;
                    font-size: 20px;
                    font-weight: 600;
                    width: 100%;
                }

                +div {
                    width: calc(100% - 479px);
                }
            }

            .ProductCard-ActionWrapper {
                display: grid;
                align-items: flex-end;

                &_isSignedIn {
                    grid-template-columns: 1fr;
                }

                .Field-Wrapper {
                    text-align: right;
                }

                .Button {
                    margin-bottom: 0;
                }

                .ProductCard-ProductActions {
                    grid-column: 1 / 3;
                    width: 100%;
                    justify-content: flex-end;
                    margin-bottom: 5px;
                }

                .ProductCard-WishListButton {
                    width: auto;
                    height: auto;
                    min-height: unset;
                }

                .ProductWishlistButton {

                    &,
                    .Button {
                        margin: 0;
                    }
                }

                .ProductCard-AddToCart {
                    margin-right: 0;
                    height: 42px;
                    min-height: 42px;
                    padding: 0 16px;
                }

                .ProductCard-Qty {
                    input {
                        height: 42px;
                        min-height: 42px;
                    }
                }
            }

            .ProductCard-Status {
                margin-bottom: -22px;
            }

            .ProductPrice-PriceCustomDiscount.ProductPrice-Price>.ProductPrice-PriceValue {
                font-size: 16px;
            }
        }

        &_grid {
            &:hover {

                &,
                .ProductCard-ProductActions {
                    z-index: 25;
                }
            }

            .ProductCard {
                &-ProductActions {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    z-index: 2;
                    margin: 0;

                    .ProductCard-WishListButton.ProductWishlistButton-Button {
                        margin: 0;

                        &.Button {

                            &.ProductWishlistButton-Button_isInWishlist,
                            &:hover {
                                height: 36px;
                                background: $secondary-color-1;

                                path {
                                    stroke: #ffffff;
                                }
                            }

                            &.ProductWishlistButton-Button_isDisabled {
                                @include button-disabled();
                            }
                        }
                    }
                }
            }
        }
    }

    &-AttributeInfo {

        p {
            &:last-child {
                margin-bottom: 0;
            }

            &,
            span {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: $neutral-color-3;
            }

            margin-bottom: 4px;

            span {
                font-weight: 600;
            }
        }
    }

    &-Author {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: $neutral-color-3;
        margin-bottom: 0;
    }

    .ProductActions {
        &-AddToCartWrapper .Button {
            margin-right: 0;
        }
    }
}